import React, { ReactNode, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { HexClasses } from "../../hex-components/classes.js";

export type EditableNameSizes = "default" | "page_title" | "project_title";

interface EditableNameStyles {
  offset: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  alignment?: string;
}

const Wrapper = styled.div<{ $styles: EditableNameStyles }>`
  position: relative;

  display: flex;

  flex: 1 1 auto;
  margin-left: ${({ $styles }) => $styles.offset};

  font-weight: ${({ $styles }) => $styles.fontWeight};
  font-size: ${({ $styles }) => $styles.fontSize};
  line-height: ${({ $styles }) => $styles.lineHeight};
  align-items: ${({ $styles }) => $styles.alignment};
  letter-spacing: -0.03em;
  overflow-wrap: break-word;

  &:hover {
    .${HexClasses.HEX_BUTTON} {
      visibility: visible;
    }
  }
  &:not(:hover) {
    .${HexClasses.HEX_BUTTON} {
      visibility: hidden;
    }
  }
`;

interface EditableNameWrapperProps {
  children: ReactNode;
  size?: EditableNameSizes;
  cyData?: string;
}

export const EditableNameWrapper: React.ComponentType<EditableNameWrapperProps> =
  React.memo(function ProjectMetadata({ children, cyData, size = "default" }) {
    const theme = useTheme();

    const styles: EditableNameStyles = useMemo(() => {
      const EDITABLE_NAME_SIZES = {
        default: {
          offset: "-1px",
          fontWeight: theme.fontWeight.SEMI_BOLD,
          fontSize: theme.fontSize.EXTRA_LARGE,
          lineHeight: "26px",
        },
        page_title: {
          offset: "-2px",
          fontWeight: theme.fontWeight.MEDIUM,
          fontSize: theme.fontSize.HEADING_TWO,
          lineHeight: "31px",
        },
        project_title: {
          offset: "-3px",
          fontWeight: theme.fontWeight.BOLD,
          fontSize: theme.fontSize.PROJECT_TITLE,
          lineHeight: "48px",
          alignment: "center",
        },
      };

      return EDITABLE_NAME_SIZES[size];
    }, [size, theme]);

    return (
      <Wrapper $styles={styles} data-cy={cyData}>
        {children}
      </Wrapper>
    );
  });
