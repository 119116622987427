import { OrgId } from "@hex/common";
import React, { useEffect, useState } from "react";

import { useOrgDetailsQuery } from "../../route/LoginRoute.generated";
import { Routes } from "../../route/routes";

export interface EmbeddedRedirectProps {
  orgId: OrgId;
  authQueryParams: Record<string, string>;
}

export const EmbeddedRedirect: React.ComponentType<EmbeddedRedirectProps> =
  React.memo(function EmbeddedRedirect({ authQueryParams, orgId }) {
    const [ssoUrl, setSsoUrl] = useState<string>();
    const { data } = useOrgDetailsQuery({
      variables: { orgId },
    });
    useEffect(() => {
      setSsoUrl(
        Routes.SSO_AUTH.getUrl({
          orgId,
          params: authQueryParams,
        }),
      );
    }, [orgId, authQueryParams]);

    const useEmbeddedRedirect =
      data?.publicOrgDetails?.ssoEnforced &&
      data.publicOrgDetails.allowsIframing;

    useEffect(() => {
      if (useEmbeddedRedirect && ssoUrl != null) {
        window.location.href = ssoUrl;
      }
    }, [ssoUrl, useEmbeddedRedirect]);

    return null;
  });
