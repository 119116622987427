import { Intent } from "@blueprintjs/core";
import { HexType, UPDATE_HEX } from "@hex/common";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";

import { useHexAOContext } from "../../hex-multiplayer/hexAOContext.js";
import { useHexSelector } from "../../hex-version-multiplayer/state-hooks/hexStateHooks";
import { useUserForMagic } from "../../hooks/magicHooks";
import { useProjectVersionEditable } from "../../hooks/sessionOrProjectEditableHooks.js";
import { buildDocumentTitle } from "../../route/user/project/constants.js";
import { CyData } from "../../util/cypress.js";
import { useProjectContext } from "../../util/projectContext";
import { BorderStyles } from "../app/ProjectDescription";
import { ProjectMetadataMagicGenerate } from "../app/ProjectMetadataMagicGenerate.js";
import { CharacterCountIndicator } from "../common/CharacterCountIndicator";
import { ControlledContentEditable } from "../common/ControlledContentEditable";
import { useToaster } from "../common/Toasts";

import { EditableNameWrapper } from "./EditableNameWrapper";
import { useMagicNameProjectMutation } from "./ProjectMetadata.generated";
import {
  DEFAULT_COMPONENT_TITLE,
  DEFAULT_PROJECT_TITLE,
  PROJECT_METADATA_PADDING,
  TITLE_MAX_LENGTH,
} from "./shared.js";

const Wrapper = styled.div`
  display: flex;
`;

export const TitleLengthLimit = styled(CharacterCountIndicator)`
  position: absolute;
  right: 3px;
  bottom: -25px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  letter-spacing: normal;
`;

export const TitleContentEditable = styled(ControlledContentEditable)<{
  $editable: boolean;
  $placeholder?: boolean;
  $placeholderText?: string;
}>`
  display: inline-block;
  width: 100%;
  max-width: 1130px;
  line-height: inherit;
  white-space: normal;
  overflow-wrap: break-word;
  padding: ${PROJECT_METADATA_PADDING}px;

  outline: none;

  &:empty {
    &:after {
      color: ${({ theme }) => theme.fontColor.PLACEHOLDER};

      content: ${({ $placeholderText }) =>
        $placeholderText != null ? `"${$placeholderText}"` : ""};
    }
  }

  ${({ $editable }) => $editable && BorderStyles}

  ${({ $placeholder }) =>
    $placeholder
      ? css`
          color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
        `
      : css`
          color: ${({ theme }) => theme.fontColor.DEFAULT};
        `}
`;

export const PROJECT_TITLE_TAG = "project-metadata-title";

interface ProjectMetadataTitleProps {
  metadataEditable: boolean;
}

export const ProjectMetadataTitle: React.ComponentType<ProjectMetadataTitleProps> =
  React.memo(function ProjectMetadataTitle({ metadataEditable }) {
    const { dispatchAO: dispatchHexAO } = useHexAOContext();
    const { hexType, hexVersionId } = useProjectContext();
    const projectVersionEditable = useProjectVersionEditable();
    const isComponent = hexType === HexType.COMPONENT;
    const { magicEnabled } = useUserForMagic();
    const toaster = useToaster();

    const canEditWithMagic = projectVersionEditable && magicEnabled;

    const [magicNameProject, { loading: magicNameLoading }] =
      useMagicNameProjectMutation({
        variables: { hexVersionId, autoTriggered: false },
      });

    const magicName = useCallback(async () => {
      try {
        await magicNameProject();
      } catch (e) {
        console.error(e);
        toaster.show({
          message:
            "We encountered an error generating your project title. Please try again later.",
          intent: Intent.DANGER,
        });
      }
    }, [magicNameProject, toaster]);

    const { projectTitle } = useHexSelector({
      selector: (hex) => ({
        lastPublisher: hex.lastPublisher,
        projectTitle: hex.title,
      }),
      equalityFn: shallowEqual,
    });

    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [editingTitle, setIsEditingTitle] = useState<boolean>(false);

    useEffect(() => {
      setCurrentTitle(projectTitle);
    }, [projectTitle]);

    const maybeFocusName = useCallback(() => {
      if (metadataEditable && !editingTitle) {
        setIsEditingTitle(true);
        if (
          projectTitle === DEFAULT_PROJECT_TITLE ||
          projectTitle === DEFAULT_COMPONENT_TITLE
        ) {
          setCurrentTitle("");
        }
      }
    }, [metadataEditable, projectTitle, editingTitle]);

    const saveName = useCallback((): void => {
      if (metadataEditable) {
        const newTitle = currentTitle
          ? currentTitle
          : isComponent
            ? DEFAULT_COMPONENT_TITLE
            : DEFAULT_PROJECT_TITLE;

        // only update the current title and send the AO event if it is under the max character count
        // and the title has changed from the original title
        if (newTitle.length <= TITLE_MAX_LENGTH && newTitle !== projectTitle) {
          dispatchHexAO(UPDATE_HEX.create("title", newTitle));
          setCurrentTitle(newTitle);
        }

        setIsEditingTitle(false);
      }
    }, [
      metadataEditable,
      currentTitle,
      isComponent,
      projectTitle,
      dispatchHexAO,
    ]);

    const cancelName = useCallback((): void => {
      const title =
        projectTitle === ""
          ? isComponent
            ? DEFAULT_COMPONENT_TITLE
            : DEFAULT_PROJECT_TITLE
          : projectTitle;
      setCurrentTitle(title);
      setIsEditingTitle(false);
    }, [projectTitle, isComponent]);

    const [isHovering, setIsHovering] = useState(false);

    return (
      <>
        {/* This will override the title provided in the various `*Routes` files, which is useful if the title has just been edited and thus us up-to-date in redux but not in the apollo cache */}
        <Helmet>
          <title>{buildDocumentTitle(currentTitle, metadataEditable)}</title>
        </Helmet>
        <Wrapper
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <EditableNameWrapper
            cyData={CyData.HEX_LOGIC_TITLE}
            size="project_title"
          >
            {canEditWithMagic && (
              <ProjectMetadataMagicGenerate
                buttonCyData={CyData.MAGIC_NAME_PROJECT}
                isLoading={magicNameLoading}
                isVisible={isHovering}
                onAction={magicName}
              />
            )}
            <TitleContentEditable
              $editable={metadataEditable && !magicNameLoading}
              $placeholder={
                magicNameLoading ||
                (!editingTitle &&
                  (currentTitle === DEFAULT_PROJECT_TITLE ||
                    currentTitle === DEFAULT_COMPONENT_TITLE))
              }
              content={
                magicNameLoading
                  ? "Generating title with Magic..."
                  : currentTitle
              }
              id={PROJECT_TITLE_TAG}
              isEditing={editingTitle}
              maxLength={TITLE_MAX_LENGTH}
              onBlur={saveName}
              onCancel={cancelName}
              onChange={setCurrentTitle}
              onClick={maybeFocusName}
              onSave={saveName}
            />
            {editingTitle && (
              <TitleLengthLimit
                count={currentTitle.length}
                max={TITLE_MAX_LENGTH}
              />
            )}
          </EditableNameWrapper>
        </Wrapper>
      </>
    );
  });
