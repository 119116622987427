import { Classes, IconSize, IntentProps, Props } from "@blueprintjs/core";
import classNames from "classnames";
import { camelCase, upperFirst } from "lodash";
import React, {
  CSSProperties,
  ComponentType,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
  memo,
} from "react";
import { useTheme } from "styled-components";

export interface CustomIconArgs {
  name: string;
  category: string;
  tags?: string[];
  transform?: string;
  path: ReactNode;
  size?: "default" | "large" | "extra-large";
  crispEdges?: boolean;
}

// these mimic the props of Icon from blueprintjs
export interface CustomIconProps
  extends IntentProps,
    Props,
    Omit<
      DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
      "ref"
    > {
  color?: string;
  stroke?: string;
  iconSize?: number;
  style?: CSSProperties;
  ref?: Ref<HTMLSpanElement>;
  svgOnly?: boolean;
}

export interface IconMeta {
  name: string;
  category: string;
  tags?: string[];
}

export const createCustomIcon = ({
  category,
  crispEdges = false,
  name,
  path,
  size = "default",
  tags,
  transform,
}: CustomIconArgs): ComponentType<CustomIconProps> & IconMeta => {
  const component = memo(
    forwardRef<HTMLSpanElement, CustomIconProps>(
      (
        {
          className,
          color,
          iconSize = IconSize.STANDARD,
          intent,
          stroke,
          style,
          svgOnly,
          ...spanProps
        },
        ref,
      ) => {
        const theme = useTheme();
        const appliedColor = color || theme.iconColor;
        let pixelGridSize: number;
        if (size === "extra-large") {
          pixelGridSize = 28;
        } else if (iconSize >= IconSize.LARGE || size === "large") {
          pixelGridSize = IconSize.LARGE;
        } else {
          pixelGridSize = IconSize.STANDARD;
        }

        const classes = classNames(
          Classes.ICON,
          Classes.intentClass(intent),
          className,
        );
        const viewBox = `0 0 ${pixelGridSize} ${pixelGridSize}`;

        const svgElem = (
          <svg
            color={appliedColor}
            data-category={category}
            data-icon={name}
            fill={appliedColor}
            height={pixelGridSize}
            shapeRendering={crispEdges ? "crispEdges" : undefined}
            stroke={stroke}
            style={svgOnly ? style : undefined}
            viewBox={viewBox}
            width={pixelGridSize}
          >
            <desc>dynamic</desc>
            <g strokeWidth="1">
              <g transform={transform}>{path}</g>
            </g>
          </svg>
        );

        if (svgOnly) {
          return svgElem;
        }

        return (
          <span ref={ref} className={classes} style={style} {...spanProps}>
            {svgElem}
          </span>
        );
      },
    ),
  );

  // displays in react dev tools
  component.displayName = `${upperFirst(camelCase(name))}Icon`;

  const castedComponent =
    component as unknown as ComponentType<CustomIconProps> & IconMeta;

  // use for displaying in spellbook
  castedComponent.name = name;
  castedComponent.category = category;
  castedComponent.tags = tags;

  return castedComponent;
};
