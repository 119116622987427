import { OrgId } from "@hex/common";
import React, { useCallback } from "react";

import { Routes } from "../../route/routes";

import { LoginOption } from "./LoginOption";

export interface SsoLoginProps {
  authQueryParams: Record<string, string>;
  embedded: boolean;
  onComplete: () => void;
  onSubmit: () => void;
  orgId: OrgId;
  className?: string;
}

export const SsoLogin: React.ComponentType<SsoLoginProps> = React.memo(
  function SsoLogin({
    authQueryParams,
    className,
    embedded,
    onComplete,
    onSubmit,
    orgId,
  }) {
    const ssoCallback = useCallback(async () => {
      onSubmit();
      const ssoUrl = Routes.SSO_AUTH.getUrl({
        orgId,
        params: authQueryParams,
      });

      if (embedded) {
        window.open(ssoUrl, "_blank");
        onComplete();
      } else {
        window.location.href = ssoUrl;
      }
    }, [embedded, orgId, authQueryParams, onSubmit, onComplete]);
    return (
      <LoginOption className={className} onClick={ssoCallback}>
        Log in with <span>SSO</span>
      </LoginOption>
    );
  },
);
